$(function() {
    setPaddings();
  $( window ).resize(function() {
    setPaddings();
  });

  $(".yadcf_reset_all").on("click", function(event) { yadcf.exResetAllFilters($(this).closest('table').DataTable()) });
});

function setPaddings(){
  var body_padding_top = $('.navbar').height();

  if ($('.header-form-actions').length > 0) {

    if ($('ol.breadcrumb').length > 0) {
      $('ol.breadcrumb').prependTo('.header-form-actions');
    }

    if($('.nav.nav-tabs').length > 0){
      $('.nav.nav-tabs').appendTo('.header-form-actions');
    }

    if($('.move-to-fixed-header').length > 0) {
      $('.move-to-fixed-header').appendTo('.header-form-actions');
    }

    if($('.header-form-actions').children().last().hasClass('nav-tabs')){
      $('.header-form-actions').css('border', 'none');
    }

    $('.header-form-actions').css('top', body_padding_top);

     body_padding_top += $('.header-form-actions').innerHeight();
  }

  $('body').css('padding-top', body_padding_top+'px');

}

/*
// trying to figure out how to use system-wide functions - none of this currently works.
// Calling showStupidMessage in view gives following error:
//     Uncaught ReferenceError: showStupidMessage is not defined
console.log('before loading')
const showStupidMessage = (msg = 'dummy') => {
    console.log(msg)
}
console.log('after loading')
showStupidMessage()
showStupidMessage('foreigner')
*/
