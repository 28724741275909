// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels");

require('jquery');
require('jquery-ui');
// include jquery in global scope
window.$ = $;

require('bootstrap');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/*
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
*/

// datatables related
require('datatables.net-bs');  // (window, window.$)
require('yadcf');              // (window, $)
require('datatables.net-rowreorder');
require('./jquery.dataTables.pageNavigationSuppression');

global.bootbox = require('bootbox');

require('./system_wide');

// import myLib from "imports-loader?imports=datatables.net";
// require ('imports-loader?imports?additionalCode=var%20define%20=%20false;!datatables.net');


// import myLib from "imports-loader?additionalCode=var%20myVariable%20=%20false;!./example.js";

// import myLib from "imports-loader?additionalCode=var%20define%20=%20false;!datatables.net";

//require('imports-loader?define=>false!datatables.net')// (window, $)

// require('imports-loader?define=>false,this=>window!datatables.net')(window, $)

